<template>
  <div class="contact-us">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item>联系我们</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>联系我们</h3>
      <div style="display: flex; border: 1px solid #dddddd; padding: 20px; line-height: 30px">
        <div style="margin-right: 40px;">
          <div style="font-weight: bold">OpenCart 开源免费PHP商城</div>
          <div>成都高新区 光大网络科技有限公司</div>
        </div>
        <div>
          <div style="font-weight: bold">电话</div>
          <div>www.opencart.cn</div>
        </div>
      </div>
      <h3>联系我们</h3>
      <el-divider></el-divider>
      <el-form label-width="120px">
        <el-form-item label="联系人">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="邮箱地址">
          <el-input></el-input>
        </el-form-item>
        <el-form-item label="留言">
          <el-input type="textarea" :row="5"></el-input>
        </el-form-item>
        <div style="display: flex;justify-content: flex-end">
          <el-button>提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contactUs'
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.contact-us {
  @include body;
}
</style>
